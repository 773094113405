const { appConfig } = window["runtimeConfig"];

const config = {
  institutionsListDocumentationUrl: appConfig.institutionsListDocumentationUrl,
  toastSuccessDismissMs: 5000,
  toastWarningDismissMs: 10000,
  toastErrorDismissMs: 15000,
};

export default config;
