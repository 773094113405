import React, { createContext, useContext, useReducer } from "react";
import appConfig from "../config/appConfig";
import {
  Toaster,
  toastVariant,
  createNewToast,
  toastReducer,
} from "../utils/Toaster";

const ToasterContext = createContext();

export const ToasterProvider = (props) => {
  const [toasts, dispatchToasts] = useReducer(toastReducer, []);

  const toastSuccess = (
    message,
    autoDismissMs = appConfig.toastSuccessDismissMs
  ) => {
    addToast(createNewToast(message, toastVariant.success), autoDismissMs);
  };

  const toastWarning = (
    message,
    autoDismissMs = appConfig.toastWarningDismissMs
  ) => {
    addToast(createNewToast(message, toastVariant.warning), autoDismissMs);
  };

  const toastError = (
    message,
    autoDismissMs = appConfig.toastErrorDismissMs
  ) => {
    addToast(createNewToast(message, toastVariant.error), autoDismissMs);
  };

  const addToast = (toast, autoDismissMs) => {
    dispatchToasts({
      type: "ADD_TOAST",
      toast: toast,
    });
    if (autoDismissMs && autoDismissMs > 0) {
      setTimeout(() => {
        onDismissToast(toast);
      }, autoDismissMs);
    }
  };

  const onDismissToast = (toast) => {
    dispatchToasts({
      type: "REMOVE_TOAST",
      toast: toast,
    });
  };

  const value = {
    toastSuccess: props.toastSuccess || toastSuccess,
    toastWarning: props.toastWarning || toastWarning,
    toastError: props.toastError || toastError,
  };

  return (
    <ToasterContext.Provider value={value}>
      <Toaster toasts={toasts} onDismissToast={onDismissToast} />
      {props.children}
    </ToasterContext.Provider>
  );
};

export const useToaster = () => {
  return useContext(ToasterContext);
};
