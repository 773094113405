import {
    FlatTable,
    FlatTableHead,
    FlatTableBody,
    FlatTableHeader,
    FlatTableRow,
    FlatTableCell
} from "carbon-react/lib/components/flat-table";
import Pill from "carbon-react/lib/components/pill";


function ServicesTable({ servicesResultSet }) {
    const boolToYesNo = (value) => (value === true) ? 'Yes' : 'No';
    const yesNoPill = (value = false) => {
        if (typeof value === "boolean") {
            return (
                <Pill colorVariant={(value === true) ? "positive" : "negative"} fill={(value === true)} pillRole="status">{boolToYesNo(value)}</Pill>
            );
        } else {
            return (
                <Pill colorVariant="neutral" pillRole="status">Unknown</Pill>
            );
        }
    };

    const tableRows = () => {
        if (
                !(servicesResultSet instanceof Object)
             || !(servicesResultSet.data instanceof Array)
             || !(servicesResultSet.data.length > 0)
           )
        {
            return (
                <FlatTableRow
                    id={`companies-table-row-noResults`}
                    key="noResults">

                    <FlatTableCell colspan="4">No results found.</FlatTableCell>
                </FlatTableRow>
            );
        }

        return servicesResultSet.data.map((service, key) =>
            (
                <FlatTableRow
                    id={`companies-table-row-${key}`}
                    key={service.id}>

                    <FlatTableCell
                        id={`services-table-cell-id-${key}`}>{service.id}</FlatTableCell>
                    <FlatTableCell
                        id={`services-table-cell-name-${key}`}>{service.name}</FlatTableCell>
                    <FlatTableCell
                        id={`services-table-cell-serviceFabricApplicationId-${key}`}>{service.serviceFabricApplicationId}</FlatTableCell>
                    <FlatTableCell
                        id={`services-table-cell-mailboxDomain-${key}`}>{service.mailboxDomain}</FlatTableCell>
                    <FlatTableCell
                        id={`services-table-cell-createMailboxes-${key}`}>{yesNoPill(service.createMailboxes)}</FlatTableCell>
                </FlatTableRow>
            )
        );
    };

    return (
        <FlatTable className="directory-table">
            <FlatTableHead>
                <FlatTableRow>
                    <FlatTableHeader>Id</FlatTableHeader>
                    <FlatTableHeader>Name</FlatTableHeader>
                    <FlatTableHeader>ServiceFabricApplicationId</FlatTableHeader>
                    <FlatTableHeader>MailboxDomain</FlatTableHeader>
                    <FlatTableHeader>CreateMailboxes</FlatTableHeader>
                </FlatTableRow>
            </FlatTableHead>
            <FlatTableBody>{tableRows()}</FlatTableBody>
        </FlatTable>
    );
};

export { ServicesTable };