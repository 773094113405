import React, { useState, useEffect } from "react";
import Heading from "carbon-react/lib/components/heading";
import { Row, Column } from "carbon-react/lib/components/row";
import Textbox from "carbon-react/lib/__experimental__/components/textbox";
import Button from "carbon-react/lib/components/button";
import { useDirectoryApi } from "../../services/DirectoryApi";
import apiConfig from "../../config/apiConfig";
import TimeTakenForSearch from "../shared/TimeTakenForSearch";
import PageSizeDropdown from "../shared/PageSizeDropdown";
import "./Companies.css";
import CompaniesTable from "./CompaniesTable";
import ToggleSwitch from "../shared/toggle-switch/ToggleSwitch";

const Companies = () => {
  const { listCompanies, getNextPageResults } = useDirectoryApi();
  const [companiesFilter, setCompaniesFilter] = useState("");
  const [companiesResultSet, setCompaniesResultSet] = useState({});
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [nextPageDisabled, setNextPageDisabled] = useState(false);
  const [previousPages, setPreviousPages] = useState([]);
  const [previousPageDisabled, setPreviousPageDisabled] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [timeTakenToSearchCompanies, setTimeTakenToSearchCompanies] =
    useState("");
  const [orderBy, setOrderBy] = useState("");
  const [searchSlider, setSearchSlider] = useState(false);
  const [predictiveSlider, setPredictiveSlider] = useState(false);
  const [predictiveDisabled, setPredictiveDisabled] = useState(true);
  const [filterSlider, setFilterSlider] = useState(false);
  const [orderBySlider, setOrderBySlider] = useState(false);
  const [apiError, setApiError] = useState("");
  const [filterErrorMessage, setFilterErrorMessage] = useState("");
  const [orderByErrorMessage, setOrderByErrorMessage] = useState("");

  useEffect(() => {
    getCompanies();
  }, [pageSize]); // eslint-disable-line

  useEffect(() => {
    setPredictiveDisabled(!searchSlider);
    if (searchSlider === false) {
      setPredictiveSlider(false);
    }
  }, [searchSlider]);
  useEffect(() => {
    if (searchQuery.length > 2 && predictiveSlider === true) {
      getCompanies();
    }
  }, [searchQuery]); // eslint-disable-line

  const getCompanies = async () => {
    setApiError("");
    setFilterErrorMessage("");
    setOrderByErrorMessage("");
    previousPages.splice(0, previousPages.length);
    updatePreviousPageDisabled();
    const companiesFilters = {
      skip: 0,
      top: pageSize === "" || pageSize === undefined ? 20 : parseInt(pageSize),
      filter: filterSlider ? companiesFilter : "",
      orderBy: orderBySlider ? orderBy : "",
      search: searchSlider && searchQuery.length > 2 ? searchQuery : "",
    };
    const timeBeforeApiCall = Date.now();
    const companiesResponse = await listCompanies(companiesFilters);
    const timeAfterApiCall = Date.now();
    setTimeTakenToSearchCompanies(timeAfterApiCall - timeBeforeApiCall);

    const previousPage = [
      `${apiConfig.baseUrl}/companies?skip=${companiesFilters.skip}&top=${companiesFilters.top}&filter=${companiesFilters.filter}&orderBy=${companiesFilters.orderBy}&search=${companiesFilters.search}`, //TODO: try to get this from some common place
    ];
    setPreviousPages(previousPage);
    showCompaniesResponse(companiesResponse);
  };

  const showCompaniesResponse = (companiesResponse) => {
    //TODO: RB - handle errors
    if (!companiesResponse.success) {
      let filterError = companiesResponse.error?.raw?.error?.details?.find(
        (x) => x.code === "invalidFilter"
      )?.message;
      if (filterError !== undefined) {
        setFilterErrorMessage(
          `${filterError}. ${companiesResponse.error?.raw?.error?.message}`
        );
      }

      let orderByError = companiesResponse.error?.raw?.error?.details?.find(
        (x) => x.target === "OrderBy"
      )?.message;
      if (orderByError !== undefined) {
        setOrderByErrorMessage(orderByError);
      }
      //display the general apierror if both the above errors are undefined
      if (filterError === undefined && orderByError === undefined) {
        setApiError(companiesResponse.error?.raw?.error?.message);
      }
    }
    if (companiesResponse?.data?.pagination?.next !== undefined) {
      setNextPageUrl(companiesResponse.data.pagination.next);
      setNextPageDisabled(false);
    } else {
      setNextPageDisabled(true);
    }
    setCompaniesResultSet(companiesResponse.data);
  };

  const updatePreviousPageDisabled = () => {
    if (previousPages.length > 1) {
      setPreviousPageDisabled(false);
    } else {
      setPreviousPageDisabled(true);
    }
  };

  const setFilterText = (e) => {
    setCompaniesFilter(e.target.value);
  };
  const setOrderByText = (e) => {
    setOrderBy(e.target.value);
  };

  const gotoPreviousPage = async () => {
    const previousPageUrl = previousPages[previousPages.length - 2];
    if (previousPages.length > 1) {
      previousPages.pop();
    }
    setPreviousPages(previousPages);
    updatePreviousPageDisabled();

    if (previousPageUrl !== undefined) {
      const companiesResponse = await getNextPageResults(previousPageUrl);
      showCompaniesResponse(companiesResponse);
    }
  };

  const gotoNextPage = async () => {
    const companiesResponse = await getNextPageResults(nextPageUrl);
    if (!previousPages.includes(nextPageUrl)) {
      previousPages.push(nextPageUrl);
    }
    setPreviousPages(previousPages);
    updatePreviousPageDisabled();
    showCompaniesResponse(companiesResponse);
  };

  const refresh = async (e) => {
    e.preventDefault();
    await getCompanies();
  };

  const setSearchText = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Row className="headingRow">
        <Column>
          <Heading title="Companies" divider={false} />
        </Column>
      </Row>
      <form onSubmit={refresh}>
        <Row className="dr-flex-row">
          <Column className="dr-flex-row slider-col-width">
            <ToggleSwitch
              checked={searchSlider}
              onChange={setSearchSlider}
              disabled={false}
            ></ToggleSwitch>
            <div className="directory-label">Search</div>
          </Column>
          <Column className="dr-flex-row search-col">
            <div className="search-box">
              <Textbox
                value={searchQuery}
                placeholder="Amazo"
                onChange={setSearchText}
              />
            </div>
          </Column>
          <Column>
            <Button buttonType="primary" type="submit">
              Refresh
            </Button>
          </Column>
        </Row>
        <Row className="dr-flex-row mb-2">
          <Column className="dr-flex-row">
            <ToggleSwitch
              checked={predictiveSlider}
              onChange={setPredictiveSlider}
              disabled={predictiveDisabled}
            ></ToggleSwitch>
            <div className="directory-label">Predictive</div>
          </Column>
        </Row>
        <Row className="dr-flex-row">
          <Column className="dr-flex-row slider-col-width">
            <ToggleSwitch
              checked={filterSlider}
              onChange={setFilterSlider}
              disabled={false}
            ></ToggleSwitch>
            <div className="directory-label">Filter</div>
          </Column>
          <Column className="dr-flex-row search-col">
            <div className="search-box">
              <Textbox
                value={companiesFilter}
                placeholder="e.g. startsWith(name,'Sag'), contains(name,'Sag')"
                onChange={setFilterText}
              />
            </div>
          </Column>
          <Column className="dr-flex-row">
            <div className="directory-label">Page size:</div>
            <PageSizeDropdown
              value={pageSize}
              setValue={setPageSize}
            ></PageSizeDropdown>
          </Column>
        </Row>
        <Row>
          <Column className="slider-col-width"></Column>
          <Column>
            <div className="error-text">{filterErrorMessage}</div>
          </Column>
        </Row>
        <Row className="dr-flex-row">
          <Column className="dr-flex-row slider-col-width">
            <ToggleSwitch
              checked={orderBySlider}
              onChange={setOrderBySlider}
              disabled={false}
            ></ToggleSwitch>
            <div className="directory-label">Order By</div>
          </Column>
          <Column className="dr-flex-row search-col">
            <div className="search-box">
              <Textbox
                value={orderBy}
                placeholder="e.g. name asc, address.city desc"
                onChange={setOrderByText}
              />
            </div>
          </Column>
        </Row>
        <Row className={`${orderByErrorMessage === "" ? "hidden-row" : ""}`}>
          <Column className="slider-col-width"></Column>
          <Column>
            <div className="error-text">{orderByErrorMessage}</div>
          </Column>
        </Row>
        <Row>
          <Column>
            <div className="error-text">{apiError}</div>
          </Column>
          <Column columnAlign="right">
            <TimeTakenForSearch
              searchExecutionTime={timeTakenToSearchCompanies}
            ></TimeTakenForSearch>
          </Column>
        </Row>
      </form>
      <CompaniesTable
        companiesResultSet={companiesResultSet}
        onDeleteCompany={() => getCompanies()}
      />
      <Row className="paginationRow">
        <Column columnAlign="right">
          <Button
            onClick={gotoPreviousPage}
            buttonType="primary"
            disabled={previousPageDisabled}
            className="paginationButtons"
          >
            Previous
          </Button>
          <Button
            onClick={gotoNextPage}
            buttonType="primary"
            disabled={nextPageDisabled}
            className="paginationButtons"
          >
            Next
          </Button>
        </Column>
      </Row>
    </>
  );
};

export default Companies;
