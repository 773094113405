import React from "react";
import Dialog from "carbon-react/lib/components/dialog";
import Textbox from "carbon-react/lib/__experimental__/components/textbox";

const ViewInstitutionDialog = ({ institution, isOpen, onCancel }) => {
  if (!institution) return null;

  return (
    <Dialog open={isOpen} onCancel={onCancel} title={institution.name}>
      <Textbox label="Id" value={institution.id} disabled={true} />
      <Textbox label="Kind" value={institution.kind} disabled={true} />
      <Textbox label="Country" value={institution.country} disabled={true} />
      <Textbox
        label="Official Ref"
        value={institution.officialRef}
        disabled={true}
      />
      <Textbox
        label="Official Tax Ref"
        value={institution.officialTaxRef}
        disabled={true}
      />
    </Dialog>
  );
};

export default ViewInstitutionDialog;
