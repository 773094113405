import React from "react";
import CarbonNavigationBar from "carbon-react/lib/components/navigation-bar";
import { Menu, MenuItem } from "carbon-react/lib/components/menu";
import { Link, useLocation } from "react-router-dom";
import appRoutes from "../../config/appRoutes";

const isSelected = (location, route) => location && location.pathname && location.pathname.startsWith(route);

const NavigationBar = () => {
  const location = useLocation();

  return (
    <CarbonNavigationBar pl={150} pr={150}>
      <Menu>
        <MenuItem selected={isSelected(location, appRoutes.institutions)}>
          <Link id="navbarLink_institutions" to={appRoutes.institutions}>
            Institutions
          </Link>
        </MenuItem>
        <MenuItem selected={isSelected(location, appRoutes.companies)}>
          <Link id="navbarLink_companies" to={appRoutes.companies}>
            Companies
          </Link>
        </MenuItem>
        <MenuItem selected={isSelected(location, appRoutes.services)}>
          <Link id="navbarLink_services" to={appRoutes.services}>
            Services
          </Link>
        </MenuItem>
      </Menu>
    </CarbonNavigationBar>
  );
};

export default NavigationBar;
