import React from "react";
import { Select, Option } from "carbon-react/lib/components/select";

const CountryDropdown = ({ value, setValue, ...props }) => {
  const countries = [
    { text: "Canada", value: "CA" },
    { text: "France", value: "FR" },
    { text: "Germany", value: "DE" },
    { text: "Ireland", value: "IE" },
    { text: "Spain", value: "ES" },
    { text: "United Kingdom", value: "GB" },
    { text: "United States", value: "US" },
  ].sort((s) => s.text);

  return (
    <Select
      label="Country"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      {...props}
    >
      {countries.map((country, key) => {
        return <Option key={key} text={country.text} value={country.value} />;
      })}
    </Select>
  );
};

export default CountryDropdown;
