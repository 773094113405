import { ThemeProvider } from "styled-components";
import { Auth0Provider } from "@auth0/auth0-react";
import mintTheme from "carbon-react/lib/style/themes/mint";
import AppWrapper from "carbon-react/lib/components/app-wrapper";
import auth0Config from "./config/auth0Config";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import Navigation from "./components/navigation/Navigation";
import appRoutes from "./config/appRoutes";
import Institutions from "./components/institutions/Institutions";
import { DirectoryApiProvider } from "./services/DirectoryApi";
import { ToasterProvider } from "./contexts/ToasterContext";

import "./App.css";
import Companies from "./components/companies/Companies";
import Services from "./components/services/Services";

const IndexComponent = () => {
  return <Redirect to={appRoutes.institutions} />;
};

function App() {
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={window.location.origin}
      audience={auth0Config.audience}
      scope={auth0Config.scope}
    >
      <DirectoryApiProvider>
        <ToasterProvider>
          <ThemeProvider theme={mintTheme}>
            <Router>
              <Navigation />
              <AppWrapper>
                <Switch>
                  <PrivateRoute
                    exact
                    path={appRoutes.institutions}
                    component={Institutions}
                  />
                  <PrivateRoute
                    exact
                    path={appRoutes.companies}
                    component={Companies}
                  />
                  <PrivateRoute
                    exact
                    path={appRoutes.services}
                    component={Services}
                  />
                  <PrivateRoute exact path="/" component={IndexComponent} />
                </Switch>
              </AppWrapper>
            </Router>
          </ThemeProvider>
        </ToasterProvider>
      </DirectoryApiProvider>
    </Auth0Provider>
  );
}

export default App;
