import apiConfig from "../../config/apiConfig";
import { v4 as uuidv4 } from "uuid";

const cloneDateTimeAndAppendSecondsOffset = (dateTime, secondsOffset) => {
  const cloned = new Date(dateTime.getTime());
  cloned.setSeconds(cloned.getSeconds() + secondsOffset);
  return cloned;
};

const invokeApiOperation = async (
  accessToken,
  urlSuffix,
  httpMethod,
  parseResponseToJson,
  bodyData = null
) => {
  try {
    // TODO: Version should come from config
    let url;
    if (urlSuffix.includes(apiConfig.baseUrl)) {
      //added this to handle moving to next page scenario where urlSuffix will include baseUrl
      url = urlSuffix;
    } else {
      url = `${apiConfig.baseUrl}${urlSuffix}`;
    }
    var headers = bodyData
      ? {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      : { Authorization: `Bearer ${accessToken}` };

    headers.IdempotencyToken = uuidv4();

    const response = await fetch(url, {
      headers,
      method: httpMethod,
      body: bodyData ? JSON.stringify(bodyData) : null,
    });

    const bodyJson =
      parseResponseToJson || !response.ok
        ? await attemptToParseResponse(response)
        : null;

    const apiResult = {
      success: response.ok,
      statusCode: response.status,
      data: response.ok && parseResponseToJson ? bodyJson : null,
    };

    if (!response.ok) {
      const firstError = parseFirstErrorFromResponseBody(bodyJson);
      apiResult.error = {
        raw: bodyJson,
        ...firstError,
      };
    }

    return Object.freeze(apiResult);
  } catch (error) {
    throw error;
  }
};

const attemptToParseResponse = async (response) => {
  try {
    return await response.json();
  } catch (error) {
    if (!logJsonParseError(response.status)) return;
    console.log(error);
  }
};

const logJsonParseError = (statusCode) => {
  if (statusCode === 403) return false;
  return true;
};

const parseFirstErrorFromResponseBody = (bodyJson) => {
  const error =
    bodyJson && Array.isArray(bodyJson.errors) && bodyJson.errors.length > 0
      ? bodyJson.errors[0]
      : null;

  return {
    source: error?.source || null,
    code: error?.code || null,
    description:
      typeof error?.description === "string" && error.description.length > 0
        ? error.description
        : "Unnexpected error.  The operation may succeed if retried.",
  };
};

const encodeUriString = (stringValue) => {
  return stringValue && stringValue.length > 0
    ? encodeURIComponent(stringValue.trim())
    : "";
};

export {
  invokeApiOperation,
  cloneDateTimeAndAppendSecondsOffset,
  encodeUriString,
};
