import React from "react";
import NavigationBar from "carbon-react/lib/components/navigation-bar";
import logo from "../../assets/sage_logo.svg";
import VerticalDivider from "carbon-react/lib/components/vertical-divider";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, MenuItem, SubmenuBlock } from "carbon-react/lib/components/menu";
import "./NavigationMain.css";

const NavigationMain = () => {
  const { isAuthenticated, logout, user } = useAuth0();

  const userMenu = () => {
    if (!isAuthenticated || !user) return;
    return (
      <Menu menuType="dark" display="flex" flex="1">
        <MenuItem submenu={user.name} className="userMenu">
          <SubmenuBlock>
            <MenuItem
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
            >
              Logout
            </MenuItem>
          </SubmenuBlock>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <NavigationBar navigationType="dark" pl={150} pr={150}>
      <img id="logo" src={logo} alt="Sage" draggable="false" className="logo" />
      <VerticalDivider height={48} p={1} tint={20} />
      <div id="title" className="title">
        Directory Service - Admin Tool
      </div>
      {userMenu()}
    </NavigationBar>
  );
};

export default NavigationMain;
