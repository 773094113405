import React, { useState } from "react";
import {
  FlatTable,
  FlatTableHead,
  FlatTableBody,
  FlatTableRow,
  FlatTableHeader,
  FlatTableCell,
} from "carbon-react/lib/components/flat-table";
import {
  ActionPopover,
  ActionPopoverItem,
} from "carbon-react/lib/components/action-popover";
import Confirm from "carbon-react/lib/components/confirm";
import { useDirectoryApi } from "../../services/DirectoryApi";
import { useToaster } from "../../contexts/ToasterContext";
import ViewCompanyDialog from "./ViewCompanyDialog";

const CompaniesTable = ({ companiesResultSet, onDeleteCompany }) => {
  const { deleteCompany } = useDirectoryApi();
  const { toastSuccess, toastError } = useToaster();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const openViewDialog = (company) => {
    setSelectedCompany(company);
    setViewDialogOpen(true);
  };

  const performDeleteCompany = async () => {
    try {
      const response = await deleteCompany(
        selectedCompany.serviceId,
        selectedCompany.externalId
      );

      if (!response.success) {
        toastError(`Failed to delete company ${selectedCompany.name}.`);
        return;
      }

      await onDeleteCompany();
      setConfirmDeleteOpen(false);

      toastSuccess(`Company ${selectedCompany.name} deleted successfully.`);
    } catch (error) {
      console.log(error);
    }
  };

  const tableRows = () => {
    if (
      !companiesResultSet ||
      !companiesResultSet?.data ||
      companiesResultSet?.data?.length === 0
    ) {
      if (
        companiesResultSet?.data !== undefined &&
        companiesResultSet?.data !== null &&
        companiesResultSet?.data?.length === 0
      ) {
        return (
          <FlatTableRow>
            <FlatTableCell colspan="6">No results found</FlatTableCell>
          </FlatTableRow>
        );
      } else {
        return null; //don't show No results found message on initial load
      }
    }
    return companiesResultSet.data.map((company, key) => {
      return (
        <FlatTableRow
          onClick={() => {
            openViewDialog(company);
          }}
          id={`companies-table-row-${key}`}
          key={company.id}
        >
          <FlatTableCell id={`companies-table-cell-name-${key}`}>
            {company.name}
          </FlatTableCell>
          <FlatTableCell id={`companies-table-cell-country-${key}`}>
            {company.country}
          </FlatTableCell>
          <FlatTableCell id={`companies-table-cell-officialref-${key}`}>
            {company.officialRef}
          </FlatTableCell>
          <FlatTableCell id={`companies-table-cell-officialtaxref-${key}`}>
            {company.officialTaxRef}
          </FlatTableCell>
          <FlatTableCell id={`search-table-cell-score-${key}`}>
            {company._score}
          </FlatTableCell>
          <FlatTableCell id={`companies-table-cell-action-${key}`}>
            <ActionPopover>
              <ActionPopoverItem
                onClick={() => {
                  openViewDialog(company);
                }}
                icon="view"
              >
                View
              </ActionPopoverItem>
              <ActionPopoverItem
                onClick={() => {
                  setSelectedCompany(company);
                  setConfirmDeleteOpen(true);
                }}
                icon="bin"
              >
                Delete
              </ActionPopoverItem>
            </ActionPopover>
          </FlatTableCell>
        </FlatTableRow>
      );
    });
  };
  return (
    <>
      <FlatTable className="directory-table">
        <FlatTableHead>
          <FlatTableRow>
            <FlatTableHeader>Name</FlatTableHeader>
            <FlatTableHeader>Country</FlatTableHeader>
            <FlatTableHeader>Official Reference</FlatTableHeader>
            <FlatTableHeader>Official Tax Reference</FlatTableHeader>
            <FlatTableHeader>Elasticsearch Score</FlatTableHeader>
            <FlatTableHeader></FlatTableHeader>
          </FlatTableRow>
        </FlatTableHead>
        <FlatTableBody>{tableRows()}</FlatTableBody>
      </FlatTable>
      <Confirm
        title="Delete Company"
        open={confirmDeleteOpen}
        onConfirm={performDeleteCompany}
        onCancel={() => setConfirmDeleteOpen(false)}
        size="medium-small"
      >
        Are you sure you want to delete this company?
      </Confirm>
      <ViewCompanyDialog
        company={selectedCompany}
        isOpen={viewDialogOpen}
        onCancel={() => setViewDialogOpen(false)}
      />
    </>
  );
};

export default CompaniesTable;
