import React, { useEffect, useState } from "react";
import Dialog from "carbon-react/lib/components/dialog";
import Button from "carbon-react/lib/components/button";
import Form from "carbon-react/lib/components/form";
import Textbox from "carbon-react/lib/__experimental__/components/textbox";
import Fieldset from "carbon-react/lib/__experimental__/components/fieldset";
import CountryDropdown from "./CountryDropdown";
import { useDirectoryApi } from "../../services/DirectoryApi";
import { useToaster } from "../../contexts/ToasterContext";

const CreateInstitutionDialog = ({ isOpen, onSave, onCancel }) => {
  const formDefaults = {
    name: "",
    country: "",
    officialRef: "",
    officialTaxRef: "",
    address: {
      kind: "",
      lines: [""],
      country: "",
      postalCode: "",
    },
  };

  const { addInstitution } = useDirectoryApi();
  const { toastSuccess, toastError } = useToaster();
  const [name, setName] = useState(formDefaults.name);
  const [country, setCountry] = useState(formDefaults.country);
  const [officialRef, setOfficialRef] = useState(formDefaults.officialRef);
  const [officialTaxRef, setOfficialTaxRef] = useState(
    formDefaults.officialTaxRef
  );
  const [addressKind, setAddressKind] = useState(formDefaults.address.kind);
  const [addressLines, setAddressLines] = useState(formDefaults.address.lines);
  const [addressCountry, setAddressCountry] = useState(
    formDefaults.address.country
  );
  const [addressPostalCode, setAddressPostalCode] = useState(
    formDefaults.address.postalCode
  );

  const resetState = () => {
    setName(formDefaults.name);
    setCountry(formDefaults.country);
    setOfficialRef(formDefaults.officialRef);
    setOfficialTaxRef(formDefaults.officialTaxRef);
    setAddressKind(formDefaults.address.kind);
    setAddressLines(formDefaults.address.lines);
    setAddressCountry(formDefaults.address.country);
    setAddressPostalCode(formDefaults.address.postalCode);
  };

  useEffect(() => {
    resetState();
  }, [isOpen]);

  const createInstitution = async () => {
    const institution = {
      name,
      country,
      officialRef,
      officialTaxRef,
      addresses: [
        {
          kind: addressKind,
          lines: addressLines,
          postalCode: addressPostalCode,
          country: addressCountry,
        },
      ],
    };

    try {
      const response = await addInstitution(institution);

      if (!response.success) {
        toastError(`Failed to create institution ${institution.name}.`);
        return;
      }

      toastSuccess(`Institution ${institution.name} created successfully.`);
      await onSave();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onCancel={() => onCancel()}
      title="Create Institution"
      subtitle="Complete the fields below to create an Institution"
    >
      <Form
        stickyFooter={true}
        leftSideButtons={<Button onClick={() => onCancel()}>Cancel</Button>}
        saveButton={
          <Button
            buttonType="primary"
            type="submit"
            onClick={async (e) => {
              e.preventDefault();
              await createInstitution();
            }}
          >
            Save
          </Button>
        }
      >
        <Textbox
          label="Name"
          value={name}
          required
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <CountryDropdown required value={country} setValue={setCountry} />
        <Textbox
          required
          label="Official Ref"
          value={officialRef}
          onChange={(e) => {
            setOfficialRef(e.target.value);
          }}
        />
        <Textbox
          required
          label="Official Tax Ref"
          value={officialTaxRef}
          onChange={(e) => {
            setOfficialTaxRef(e.target.value);
          }}
        />
        <Fieldset legend="Address">
          <Textbox
            required
            label="Kind"
            labelInline
            labelAlign="right"
            value={addressKind}
            onChange={(e) => {
              setAddressKind(e.target.value);
            }}
          />
          {addressLines.map((addressLine, index) => {
            const displayIndex = index + 1;
            return (
              <Textbox
                {...(index === 0 ? { required: true } : {})}
                label={`Line ${displayIndex}`}
                labelInline
                labelAlign="right"
                value={addressLine}
                onChange={(e) => {
                  const updatedAddressLines = [...addressLines];
                  updatedAddressLines[index] = e.target.value;
                  setAddressLines(updatedAddressLines);
                }}
              />
            );
          })}
          <CountryDropdown
            required
            labelInline
            value={addressCountry}
            setValue={setAddressCountry}
          />
          <Textbox
            required
            label="Postal Code"
            labelInline
            labelAlign="right"
            styleOverride={{
              input: {
                width: "120px",
                flex: "none",
              },
            }}
            value={addressPostalCode}
            onChange={(e) => {
              setAddressPostalCode(e.target.value);
            }}
          />
        </Fieldset>
      </Form>
    </Dialog>
  );
};

export default CreateInstitutionDialog;
