import './ServicesDialog.css';

import { useDirectoryApi } from "../../services/DirectoryApi";
import { useToaster } from "../../contexts/ToasterContext";

import { Formik } from "formik";
import ServiceSchema from "./ServiceSchema";

import { GridContainer, GridItem } from "carbon-react/lib/components/grid";

import Dialog from "carbon-react/lib/components/dialog";
import Textbox from "carbon-react/lib/__experimental__/components/textbox";
import Button from "carbon-react/lib/components/button";
import Switch from "carbon-react/lib/__experimental__/components/switch";
import Loader from "carbon-react/lib/components/loader";

const ServiceDialog = ({ purpose = "Edit", isOpen, onCancel, onSuccess }) => {
  const api = useDirectoryApi();
  const toaster = useToaster();

  const onFormSubmit = async (values, bag) => {
    const resp = await api.addService({
      name: values.name.trim(),
      serviceFabricApplicationId: values.serviceFabricApplicationId.trim(),
      createMailboxes: values.createMailboxes,
      mailboxDomain: values.mailboxDomain.trim()
    });

    if (resp.success) {
      toaster.toastSuccess(`Service "${values.name.trim()}" created successfully.`);
      if (onSuccess instanceof Function) {
        onSuccess();
      }
      bag.setIsSubmitting(false);
      return true;
    }

    toaster.toastError(`(${resp.statusCode}) An error occurred while processing your request.`);
    bag.setIsSubmitting(false);
    return false;
  };

  const initialFormValues = ServiceSchema.default();
  const dialogTitle = `${purpose} Service`;

  return (
    <Dialog open={isOpen} title={dialogTitle}>
      <Formik
        onSubmit={onFormSubmit}
        initialValues={initialFormValues}
        validationSchema={ServiceSchema}
        validateOnMount={true}
      >
          {props => (
            <form onSubmit={props.handleSubmit}>
              {/* ID is Needed since the CSS rules for Grid Shape is Too Specific in Carbon */}
              <GridContainer id="add-service-dialog">
                <GridItem gridColumn="1 / 3" gridRow="1 / 2">
                  <Textbox
                    label="Name"
                    name="name"
                    required={true}
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled={props.values.isSaving} />
                </GridItem>

                <GridItem gridColumn="1 / 3" gridRow="2 / 3">
                  <Textbox
                    label="ServiceFabric Application ID"
                    name="serviceFabricApplicationId"
                    required={false}
                    value={props.values.serviceFabricApplicationId}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled={props.values.isSaving} />
                </GridItem>

                <GridItem gridColumn="1 / 3" gridRow="3 / 4">
                  <Switch
                    label="Create Mailboxes?"
                    name="createMailboxes"
                    required={false}
                    checked={props.values.createMailboxes}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled={props.values.isSaving} />
                </GridItem>

                <GridItem gridColumn="1 / 3" gridRow="4 / 5">
                  <Textbox
                    label="Mailbox Domain"
                    name="mailboxDomain"
                    required={props.values.createMailboxes}
                    value={props.values.mailboxDomain}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled={props.values.isSaving || props.values.createMailboxes === false} />
                </GridItem>

                <GridItem
                  justifySelf="right"
                  gridColumn="1 / 2"
                  gridRow="5 / 6">
                  <Button
                    name="submit"
                    type="submit"
                    disabled={props.isValid === false}
                    buttonType="primary">
                      {props.isSubmitting ? (<Loader />) : "Create"}
                  </Button>
                </GridItem>
                <GridItem
                  justifySelf="left"
                  gridColumn="2 / 3"
                  gridRow="5 / 6">
                  <Button
                    name="cancel"
                    buttonType="secondary"
                    disabled={props.isSubmitting}
                    onClick={onCancel}>
                      Cancel
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          )}
      </Formik>
    </Dialog>
  );
};

export default ServiceDialog;
