import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext } from "react";
import auth0Config from "../config/auth0Config";
import { invokeApiOperation } from "./common/ApiHelper";

const DirectoryApiContext = createContext();
const HttpMethod = {
  Post: "POST",
  Get: "GET",
  Delete: "DELETE",
  Put: "PUT",
};

export const DirectoryApiProvider = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  const addInstitution = async (institution) => {
    return await invokeApi(
      `/institutions`,
      HttpMethod.Post,
      false,
      institution
    );
  };

  const addService = async (service) => {
    return await invokeApi(
      `/services`,
      HttpMethod.Post,
      false,
      service
    );
  };

  const getInstitution = async (institutionId) => {
    const response = await invokeApi(
      `/institutions/${institutionId}`,
      HttpMethod.Get,
      true
    );
    //TODO: Do we need to check the response.success here?
    return response.data.data;
  };

  const deleteInstitution = async (institutionId) => {
    return await invokeApi(
      `/institutions/${institutionId}`,
      HttpMethod.Delete,
      false
    );
  };

  const listInstitutions = async (queryParams) => {
    return await invokeApi(
      `/organisations?skip=${queryParams.skip}&top=${queryParams.top}&filter=${queryParams.filter}&orderBy=${queryParams.orderBy}&search=${queryParams.search}`,
      HttpMethod.Get,
      true
    );
  };

  const listCompanies = async (queryParams) => {
    return await invokeApi(
      `/companies?skip=${queryParams.skip}&top=${queryParams.top}&filter=${queryParams.filter}&orderBy=${queryParams.orderBy}&search=${queryParams.search}`,
      HttpMethod.Get,
      true
    );
  };

  const listServices = async () => {
    return await invokeApi(
      `/services`,
      HttpMethod.Get,
      true
    );
  };

  const deleteCompany = async (serviceId, externalId) => {
    return await invokeApi(
      `/companies/${serviceId}/${externalId}`,
      HttpMethod.Delete,
      false
    );
  };
  const getNextPageResults = async (nextPageUrl) => {
    return await invokeApi(nextPageUrl, HttpMethod.Get, true);
  };
  const invokeApi = async (
    urlSuffix,
    httpMethod,
    parseResponseToJson,
    bodyData = null
  ) => {
    const token = await getAccessTokenSilently({
      audience: auth0Config.audience,
    });

    return await invokeApiOperation(
      token,
      urlSuffix,
      httpMethod,
      parseResponseToJson,
      bodyData
    );
  };

  const value = {
    addInstitution: props.addInstitution || addInstitution,
    addService: props.addService || addService,
    getInstitution: props.getInstitution || getInstitution,
    deleteInstitution: props.deleteInstitution || deleteInstitution,
    listInstitutions: props.listInstitutions || listInstitutions,
    listCompanies: props.listCompanies || listCompanies,
    listServices: props.listServices || listServices,
    deleteCompany: props.deleteCompany || deleteCompany,
    getNextPageResults: props.getNextPageResults || getNextPageResults,
  };
  return (
    <DirectoryApiContext.Provider value={value}>
      {props.children}
    </DirectoryApiContext.Provider>
  );
};
export const useDirectoryApi = () => {
  return useContext(DirectoryApiContext);
};
