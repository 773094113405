import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ checked, onChange, disabled }) => {
  return (
    <label class="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <span class="slider round"></span>
    </label>
  );
};

export default ToggleSwitch;
