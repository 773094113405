import { object, string, boolean } from "yup";
import isValidDomain from "is-valid-domain";

const ServiceSchema = object().shape({
  name:
    string().required()
    .default(''),
  serviceFabricApplicationId:
    string()
    .default(''),
  createMailboxes:
    boolean()
    .default(false),
  mailboxDomain:
    string()
    //   Using 'is-valid-domain' since Yup doesn't provide a stable method for checking
    // email addresses.  '.email()' will only validate with something such as 'bob@sage.com';
    // and '.domain()' requires 'http(s)'.
    //   They are also having issues with their domain validation in general, it seems
    // (accessed on 28/04/2022):
    //   https://github.com/jquense/yup/issues/507
    .test(
      "isValidEmailDomain",
      // It looks like a backtick substituion, but it's not; it's a Yup thing.
      "'${originalValue}' is not a valid domain.",
      (value) => isValidDomain(value, {
        subdomain: true,
        wildcard: false,
        allowUnicode: false,
        topLevel: false
      })
    )
    .when("createMailboxes", { is: true, then: string().required() })
    .default('')
});

export default ServiceSchema;
