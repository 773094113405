import "./Services.css";

import { useState, useEffect } from "react";

import Heading from "carbon-react/lib/components/heading";
import {
  Row, Column
} from "carbon-react/lib/components/row";
import Button from "carbon-react/lib/components/button";
import Loader from "carbon-react/lib/components/loader";

import { useDirectoryApi } from "../../services/DirectoryApi";
import { useToaster } from "../../contexts/ToasterContext";

import { ServicesTable } from "./ServicesTable";
import ServiceDialog from "./ServicesDialog";

/**
 * Create the "/services" page.
 * 
 * @returns {React.Component} Services page root component.
 */
export default function Services() {
  /**
   * Get the API, and create a state for storing the Services.
   */
  const api = useDirectoryApi();
  const toaster = useToaster();
  const [loadedServices, setLoadedServices] = useState({
    first: true, // trigger Services to be loaded the first time
    success: true,
    statusCode: 200,
    data: { data: [] }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  /**
   * Load all Services from the API server.
   *  
   * @param {Function<Array>?} callback Optional callback; fired when load is complete.
   * @returns {void}
   */
  const loadServices = async (callback) => {
    setIsLoading(true);
    let apiServices = await api.listServices();
    setLoadedServices(apiServices.data);
    setIsLoading(false);

    if (
      (apiServices.error instanceof Object)
      && (typeof apiServices.error.description === "string")
    ) {
      toaster.toastError(apiServices.error.description);
    }

    if (typeof callback === "function") {
      callback(loadedServices.status ? loadedServices.data.data : []);
    }
  };

  /**
   *   When the page is first loaded we need to download the list of Services from
   * the server.  Here we check first if we have appropriate data loaded, and if
   * we do not, then we load and store the Services.
   */
  useEffect(() => {
    if (typeof loadedServices.first === "boolean" && loadedServices.first) {
      loadServices();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedServices]);

  const onClickCreate = () => setIsCreating(true);
  const onCancelCreate = () => {
    setIsCreating(false);
  }
  const onSuccessCreate = () => {
    setIsCreating(false);
    loadServices();
  }

  /**
   * Create the "/services" page.
   */
  return (
    <>
      <Column>
        <Row className="headingRow">
          <Column>
            <Heading title="Services" divider={false} />
          </Column>
          <Column columnAlign="right">
            <Button
              buttonType="primary"
              mr="1"
              key="add-btn"
              disabled={isCreating}
              onClick={onClickCreate}
            >
              {isCreating ? (<Loader />) : "Create"}
            </Button>

            <Button
              buttonType="primary"
              key="refresh-btn"
              disabled={isLoading}
              onClick={loadServices}
            >
              {isLoading ? (<Loader />) : "Refresh"}
            </Button>
          </Column>
        </Row>
        <ServicesTable servicesResultSet={loadedServices} />
      </Column>
      <ServiceDialog purpose="Create" isOpen={isCreating} onCancel={onCancelCreate} onSuccess={onSuccessCreate} />
    </>
  );
}
