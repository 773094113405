import React from "react";
import { Select, Option } from "carbon-react/lib/components/select";

const PageSizeDropdown = ({ value, setValue }) => {
  const pageSizes = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "75", value: 75 },
    { text: "100", value: 100 },
  ].sort((s) => s.text);

  return (
    <Select
      className="page-size"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    >
      {pageSizes.map((pageSize, key) => {
        return <Option key={key} text={pageSize.text} value={pageSize.value} />;
      })}
    </Select>
  );
};

export default PageSizeDropdown;
