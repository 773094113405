const TimeTakenForSearch = ({ searchExecutionTime }) => {
  if (
    searchExecutionTime === "" ||
    searchExecutionTime === null ||
    searchExecutionTime === undefined
  )
    return null;
  return (
    <>
      <div className="directory-label">
        Search Execution Time: {searchExecutionTime} ms
      </div>
    </>
  );
};

export default TimeTakenForSearch;
