import React from "react";
import { v4 as uuidv4 } from "uuid";
import Toast from "carbon-react/lib/components/toast";

const toastVariant = {
  success: "success",
  warning: "warning",
  error: "error",
};

const createNewToast = (message, variant) => {
  return {
    id: uuidv4(),
    variant: variant,
    message: message,
  };
};

const toastReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return [...state, action.toast];
    case "REMOVE_TOAST":
      const idx = state.findIndex((toast) => toast.id === action.toast.id);
      if (idx >= 0) state.splice(idx, 1);
      return [...state];
    default:
      return state;
  }
};

const Toaster = ({ toasts, onDismissToast }) => {
  const renderToasts = (toastVariant) => {
    return (
      <>
        {toasts && toasts.length > 0
          ? toasts
              .filter((toast) => toast.variant === toastVariant)
              .map((toast) => {
                return (
                  <Toast
                    key={`toast${toast.id}`}
                    open={true}
                    variant={toastVariant}
                    onDismiss={() => onDismissToast(toast)}
                  >
                    {toast.message}
                  </Toast>
                );
              })
          : null}
      </>
    );
  };

  return (
    <>
      {renderToasts(toastVariant.success)}
      {renderToasts(toastVariant.warning)}
      {renderToasts(toastVariant.error)}
    </>
  );
};

export { Toaster, toastVariant, createNewToast, toastReducer };
