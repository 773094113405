import React from "react";
import NavigationMain from "./NavigationMain";
import NavigationBar from "./NavigationBar";

const Navigation = () => {
  return (
    <>
      <NavigationMain />
      <NavigationBar />
    </>
  );
};

export default Navigation;
