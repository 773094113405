const { auth0Config } = window["runtimeConfig"];

const config = {
  domain: auth0Config.domain,
  clientId: auth0Config.clientId,
  audience: auth0Config.audience,
  scope: auth0Config.scope,
};

export default config;
